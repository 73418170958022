import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './index.scss';
import Login from './Pages/Login/Login';
import Summary from './Pages/Summary/Summary';
import Settings from './Pages/Settings/Settings';
import Orders from './Pages/Orders/Orders';
import Locations from './Pages/Locations/Locations';
import Stores from './Pages/Stores/Stores';
import Error from './Pages/Error/Error';
import Page from './Components/Page/Page';
import reportWebVitals from './reportWebVitals';
import { AuthProtection } from './Utilities/Auth/AuthProtection'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons';

import "./Utilities/Locale/i18n";
import LocationSuggestions from './Pages/LocationSuggestions/LocationSuggestions';
import Scans from './Pages/Scans/Scans';
import RangeCheck from './Pages/RangeCheck/RangeCheck';
import GlobalError from './Components/GlobalError/GlobalError';
import RangeGenerate from './Pages/RangeGenerate/RangeGenerate';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/serviceWorker.js');
}

library.add(fas);
library.add(far);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="oglSJgYtMy/:key" element={<div className='page'><div><RangeCheck /></div><GlobalError /></div>} />
        <Route path="wUys3MGpQo/:key" element={<div className='page'><div><RangeGenerate /></div><GlobalError /></div>} />

        <Route element={<AuthProtection />}>
          <Route path="summary" element={<Page id="summary" title="summary"><Summary /></Page>} />
          <Route path="shops" element={<Page id="shops" title="shops"><Summary endpoint='shops_summary' /></Page>} />
          <Route path="wheel" element={<Page id="wheel" title="wof"><Summary endpoint='wheel_summary' /></Page>} />
          <Route path="orders" element={<Page id="orders"><Orders /></Page>} />
          <Route path="locations" element={<Page id="locations"><Locations /></Page>} />
          <Route path="locations/suggestions" element={<Page id="location-suggestions"><LocationSuggestions /></Page>} />
          <Route path="stores" element={<Page id="stores"><Stores /></Page>} />
          <Route path="scans" element={<Page id="scans" title="scans"><Scans /></Page>} />
          <Route path="range-check" element={<Page id="range-check"><RangeCheck /></Page>} />
          <Route path="range-generate" element={<Page id="range-generate"><RangeGenerate /></Page>} />
          <Route path="settings/view" element={<Page id="settings" title="settings"><Settings page="view"/></Page>} />
          <Route path="settings/about" element={<Page id="settings" title="settings"><Settings page="about"/></Page>} />

          <Route path="logout" element={<Navigate to="/login" replace />} />
        </Route>

        <Route index path="/" element={<Navigate to="/summary" replace />} />
        <Route path="*" element={<Error code="404" />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
