import { useEffect, useState } from "react"
import { useApi } from "../Api"
import { Summary } from "../Types/Summary"

type Response = Summary

export const useGetSummaryApi = (endpoint: string) => {
    const { loading, send: sendBase } = useApi();
    const [ response, setResponse ] = useState<Response>();
    const [ error, setError ] = useState({ });

    const refresh = () => {
        setResponse(undefined);
        
        sendBase<Response>(endpoint, "POST", {})
            .then(setResponse)
            .catch(setError);
    }

    useEffect(() => {
        refresh();
    }, [endpoint])

    return {
        loading,
        response,
        error, 
        refresh
    }
}