import { useGetSummaryApi } from '../../Utilities/Api';
import './Summary.scss';
import { EmptyList, LoaderIcon, Spinner } from '../../Components/UI';
import Tile from './Tile';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../Utilities/Settings/UseSettings';

type Props = {
  endpoint?: string
};

export default function Summary({ endpoint="summary" }: Props)
{
  const { t } = useTranslation("summary");
  const { theme } = useSettings();
  const { response } = useGetSummaryApi(endpoint);

  if(!response) 
    return <div className='summaryLoading'>
      <Spinner><LoaderIcon color={theme==="light" ? "#0064e8" : "#bbb"} size={36}/></Spinner>
      <h2>{t("loading")}</h2>
    </div>;

  return (
    <div className="summary">
      {response.tiles.length === 0
        ? <div className='tile'><EmptyList text="Graphs will show once you add payments."/></div>
        : response.tiles.map((tile, i) => (
          <Tile  key={i} tile={tile}/>
        ))
      }
    </div>
  );
}
